.wrapperAuth {
    background-color:#ffffff;
    background-blend-mode:overlay;
    display:flex;
    align-items:center;
    justify-content:center;
    /* background-image:url(../../assets/img/image4.jpg); */
    background-repeat:no-repeat;
    background-size:cover;
    height:100%;
  }
  
  body {
    background-color:transparent;
  }
  
  .registration-cssave{
    padding:50px 0;
  }
  
  .registration-cssave form {
    max-width:800px;
    padding:50px 70px;
    border-radius:10px;
    box-shadow:4px 4px 15px rgba(0, 0, 0, 0.2);
    background-color:#fff;
  }
  
  .registration-cssave form h3 {
    text-align: center;
    font-weight:bold;
    margin-bottom:30px;
  }
  
  .registration-cssave .itemAuth {
    border-radius:10px;
    margin-bottom:25px;
    padding:10px 20px;
  }

  .wrapperAuth {
    display: flex;
    justify-content: center;
  }
  
  .registration-cssave .create-account {
    border-radius:30px;
    padding:10px 20px;
    font-size:18px;
    font-weight:bold;
    background-color:#3f93ff;
    border:none;
    color:white;
    margin-top:20px;
  }

  button.btn {
    border-radius:30px;
    padding:10px 20px;
    font-size:18px;
    font-weight:bold;
    background: rgb(63,147,255);
    border:none;
    color:white;
    text-decoration: none;
    box-shadow: 0 -1px rgb(37, 88, 154) inset;
    transition: 0.2s;
    margin-top:20px;
}

button.btn:hover { background: rgb(37, 88, 154); }
button.btn:active {
    background: rgb(63,147,255);
    box-shadow: 0 3px rgb(63,147,255) inset;
}
  
  @media (max-width: 576px) {
    .registration-cssave form {
      padding:50px 20px;
    }
  }