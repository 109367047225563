.filters {
    align-items: center;
    padding: 0 20px;
}

.options {
    color: black;
}

.text {
    margin: 0 15px;
}

.text_info {
    margin: 0 15px;
    text-align: center;
}

.list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 15px;
    margin: 0 10px;
}

.itemCreo {
    position: relative;
    width: 400px;
    margin: 10px;
    /* font-family: "Trebuchet MS", "Lucida Sans"; */
    border-radius: 5px;
    box-shadow: 2px -2px 5px 0 rgba(0,0,0,.1),
         -2px -2px 5px 0 rgba(0,0,0,.1),
        2px 2px 5px 0 rgba(0,0,0,.1),
        -2px 2px 5px 0 rgba(0,0,0,.1);
    font-size: 16px;
    letter-spacing: 2px;
    transition: 0.3s all linear;
}

.sortTab {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
}

.webBtnSort {
    border: none;
    width: 120px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 9;
    height: 40px;
    color: white;
    background-color: #65a2ec;
    text-align: center;
    transition: .2s linear;
}

.webBtnSort.active {
    border: 1px dashed #3C82F8;
    background-color: #426897;
    /* box-shadow: 0 0 0 2px white, 0 0 0 4px #3C82F8; */
}

.webBtnSort:hover {
    box-shadow: 0 0 0 2px white, 0 0 0 4px #3C82F8;
}

.custom {
    z-index: 3;
}

@media (max-width: 730px) {
    .item, .itemBan, .itemBroke {
        font-size: 13px;
        letter-spacing: 1px;
    }
}

@media (max-width: 660px) {
    .item, .itemBan, .itemBroke {
        letter-spacing: 0px;
    }
}

@media (max-width: 440px) {
    .item, .itemBan, .itemBroke {
        margin-right: 5px;
        /* padding: 0px 5px; */
    }
}

@media (max-width: 413px) {
    .item, .itemBan, .itemBroke {
        margin-right: 2px;
    }
}
