/* .title {
    text-align: center;
} */

.form {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    padding: 20px;
	font-family: "Trebuchet MS", "Lucida Sans";
}

.filtersCreoProfit {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Прижимаем селекты к левому краю */
    margin-bottom: 20px; /* Отступ между блоками */
    padding: 10px; /* Отступ внутри блока */
    border: 1px solid #ccc; /* Граница вокруг блока */
    border-radius: 5px; /* Закругление углов */
    background-color: #f9f9f9; /* Цвет фона */
}

.options {
    color: black;
    margin: 0 15px;
}

.text {
    margin-right: 10px;
}

.creoProfits {
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
}

.tableCreoProfits {
	width: 100%;
    height: 400px;
	border: none;
}

.tableCreoProfits thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
	font-weight: bold;
	text-align: left;
	border: none;
	padding: 10px 15px;
	background: #4074f7;
	font-size: 14px;
}

.tabletableCreoProfits thead tr th:first-child {
	border-radius: 8px 0 0 8px;
}

.tableCreoProfits thead tr th:last-child {
	border-radius: 0 8px 8px 0;
}

.tabletableCreoProfits thead tr th:hover {
    cursor: pointer;
}

.tableCreoProfits tbody td {
	text-align: left;
	border: 1px solid #4074f7;
	padding: 10px 10px;
	font-size: 14px;
	vertical-align: top;
}

.tableCreoProfits tbody tr:nth-child(even){
	background: #ffffff;
}

.tableCreoProfits tbody tr td:first-child {
    border-radius: 1px 0 0 1px;
}

.tableCreoProfits tbody tr td:last-child {
	border-radius: 0 1px 1px 0;
}

.webBtnSortCreoProfits {
    border: none;
    /* width: 120px; */
    border-radius: 5px;
    cursor: pointer;
    z-index: 9;
    /* height: 40px; */
    color: white;
    background-color: transparent;
    text-align: center;
    transition: .2s linear;
}

.webBtnSortCreoProfits.active {
    border: 1px dashed #3C82F8;
    background-color: #426897;
    /* box-shadow: 0 0 0 2px white, 0 0 0 4px #3C82F8; */
}

.webBtnSortCreoProfits:hover {
    box-shadow: 0 0 0 2px white, 0 0 0 4px #3C82F8;
}

/* .webBtnMyCreo {
    border: none;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    z-index: 9;
    height: 30px;
    color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, .1);;
    text-align: center;
    transition: .2s linear;
}

.webBtnSort:hover {
    box-shadow: 0 0 0 2px white, 0 0 0 4px #3C82F8;
} */