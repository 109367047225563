/* .element {
    display: flex;
    flex-direction: column;
} */

.elementTest {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    /* padding: 20px;
    margin: 15px 0; */
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    transition: box-shadow 0.3s ease;
}

.elementTest:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}

.wrapTest {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Обёртка для информации */
.wrapperTest {
    /* display: flex; */
    min-height: 100px;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

/* Название приложения */
.appNameTest {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.iconWrapTest {
    display: flex;
    flex-direction: column;
}

/* Иконка платформы (android, ios) */
.iconTest {
    width: 18px;
    height: 18px;
    margin-left: 10px;
    margin-bottom: 5px;
}

/* Основное описание */
.discription_mainTest {
    font-size: 14px;
    color: #666;
}

/* Возрастная информация */
.title_mainTest {
    font-size: 14px;
    color: #333;
}

/* Блок информации о релизе и статусе */
.titleTest {
    font-size: 14px;
    font-weight: normal;
    color: #555;
}

.linkTest a {
    color: #007bff;
    text-decoration: none;
}

.linkTest a:hover {
    text-decoration: underline;
}

/* Секция с дополнительной информацией */
.discriptionAppsTest {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 0 10px;
}

/* Выпадающий список */
.smallOptionsTest {
    width: 100px;
    z-index: 10;
}

.textTest {
    font-weight: normal;
    color: #333;
}

/* Статусы источников */
.titleTest {
    font-size: 14px;
}

.titleTest span {
    font-weight: bold;
}

.titleTest::before {
    margin-right: 5px;
}

/* Гео информация */
.wrapperGeosTest {
    margin-top: 10px;
}

.bcg {
    height: auto;
    opacity: 0.2;
    position: absolute;
    background-size: cover;
    max-width: 92%;
    margin: 0;
    z-index: -1;
}

/* .icon {
    width: 10px;
    height: 10px;
    margin: 0 5px;
} */

/* .discriptionApps {
    margin: 10px 0 30px 0;
    padding: 0px 5px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-gap: 10px 10px;
}

.wrap {
    display: flex;
    flex-direction: column;
}

.wrapper {
    margin: 5px 10px;
    max-width: 100%;
} */

/* .wrapperGeos {
    margin: 0px 0px 50px 10px;
}

.appName {
    font-size: 1.5em;
}

.title_main,
.discription_main {
    font-size: 1.1em;
}

.smallOptions {
    color: black;
    width: 60%;
    z-index: 10;
} */

/* .appName {
    cursor: pointer;
}

.discription_main {
    margin: 2px 0;
} */

.discription {
    font-size: 1em;
    margin: 2px 0;
}

.center_link {
    margin: 2px 0;
}

.center_link_copy {
    margin: 4px 0;
}

/* .link, .center_link {
    font-size: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} */

.link_copy, .center_link_copy {
    font-size: 0.7em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.link_copy:hover, .center_link_copy:hover {
    text-decoration: underline;
}

.link_tg {
    color: var(--tg-theme-link-color);
}

.add-btn {
    width: 100%;
    padding: 5px 10px;
    background-color: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    border-radius: 5px;
    outline: none;
    cursor: pointer;
}

.webBtn {
    position: absolute;
    bottom: 0;
    border: none;
    border-radius: 0px 0px 5px 5px;
    cursor: pointer;
    z-index: 9;
    width: 100%;
    height: 30px;
    color: white;
    background-color: #65a2ec;
    text-align: center;
    transition: .2s linear;
}

.webBtn:hover {
    box-shadow: 0 0 0 2px white, 0 0 0 4px #3C82F8;
}

.btnHide {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

.btnHide:hover {
    text-decoration: underline;
}

.tooltip {
    cursor: pointer;
}


/* @media (max-width: 610px) {
    .wrapper {
        margin: 5px 10px;
    }

    .wrapperGeos {
        grid-column: 1 / span 2;
    }
}

@media (max-width: 592px) {
    .wrapper {
        margin: 5px 6px;
    }

    .wrapperGeos {
        grid-column: 1 / span 2;
    }
}

@media (max-width: 575px) {
    .wrapper {
        margin: 5px 0px;
    }

    .wrapperGeos {
        grid-column: 1 / span 2;
    }
}

@media (max-width: 547px) {
    .wrap {
        grid-gap: 0px 0px;
    }

    .title_main,
    .appName,
    .discription_main {
        font-size: 1.2em;
    }

    .discription_main {
        margin: 1px 0;
    }

    .discription, .title {
        font-size: 0.9em;
        margin: 1px 0;
    }

    .add-btn {
        font-size: 1em;
    }
}

@media (max-width: 470px) {
    .title_main,
    .appName,
    .discription_main {
        font-size: 1em;
    }
} */
