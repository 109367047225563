.containerCreo {
    /* width: 100%; */
    position: relative;
    cursor: pointer;
}


/* @media (max-width: 870px) {
    .containerCreo {
        display: contents;
    }
} */