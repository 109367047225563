/* .mysidenav {
    background: #404f6a !important;
    opacity: 0.7 !important;
  } */

  .navbar {
    background-color: #ffffff;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
    color: #000;
  }
  
  .nav-menu {
    background-color: #ffffff;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 11;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 450ms;
    overflow: auto;
  }

  .nav-menu.active::-webkit-scrollbar {
    width: 10px;
  }
  
  .nav-menu.active::-webkit-scrollbar-track {
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
    background-color: #f9f9fd;
    border-radius: 10px;
  }
  
  .nav-menu.active::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #77a7f5;
  }
  
  .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #151414;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #000000;
    color: #fff;
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle {
    background-color: #ffffff;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }