.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    z-index: 10;
}

.modal.active {
    opacity: 1;
    pointer-events: all;
}

.modal-content {
    /* width: 80%; */
    font-family: "Trebuchet MS", "Lucida Sans";
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    transform: scale(0.5);
    transition: 0.4s all;
}

.modal-content.active {
    transform: scale(1);
}

.modal-close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 30px;
    height: 30px;
    padding: 0;
    background-color: transparent;
}
