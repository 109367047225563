/* .title {
    text-align: center;
} */

.form {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    padding: 20px;
}

.options {
    color: black;
}

.text {
    margin: 0 15px;
}

.table {
	width: 100%;
	border: none;
	margin-bottom: 20px;
}
.table thead th {
	font-weight: bold;
	text-align: left;
	border: none;
	padding: 10px 15px;
	background: #4074f7;
	font-size: 14px;
}
.table thead tr th:first-child {
	border-radius: 8px 0 0 8px;
}
.table thead tr th:last-child {
	border-radius: 0 8px 8px 0;
}
.table tbody td {
	text-align: left;
	border: none;
	padding: 10px 15px;
	font-size: 14px;
	vertical-align: top;
}
.table tbody tr:nth-child(even){
	background: #6987bc;
}
.table tbody tr td:first-child {
	border-radius: 8px 0 0 8px;
}
.table tbody tr td:last-child {
	border-radius: 0 8px 8px 0;
}

.tdTable {
	text-align: center !important;
}