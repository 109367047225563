.container {
    height: 30vh;
    width: 100%;
    position: relative;
}

.img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.img:hover {
    cursor: pointer;
    opacity: 0.7;
}