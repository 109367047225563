.element {
    display: flex;
    flex-direction: column;
}

.opacity-50 {
    opacity: 0.5;
  }

.bcg {
    height: auto;
    opacity: 0.2;
    position: absolute;
    background-size: cover;
    max-width: 92%;
    margin: 0;
    z-index: -1;
}

.icon {
    width: 10px;
    height: 10px;
    margin: 0 5px;
}

.wrapCreo {
    display: flex;
    flex-direction: column;
}

.discriptionCreo {
    margin: 10px 0;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-gap: 10px 5px;
}

.wrapperCreo {
    margin: 5px 10px;
    max-width: 100%;
}

.title_main,
.appName,
.discription_main {
    font-size: 1.5em;
}

.smallOptions {
    color: black;
    width: 60%;
}

.appName {
    cursor: pointer;
}

.discription_main {
    margin: 2px 0;
}

.discription {
    font-size: 1em;
    margin: 2px 0;
}

.center_link {
    margin: 2px 0;
}

.center_link_copy {
    margin: 4px 0;
}

.linkTest, .center_link {
    font-size: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.link_copy, .center_link_copy {
    font-size: 0.7em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.link_copy:hover, .center_link_copy:hover {
    text-decoration: underline;
}

.link_tg {
    color: var(--tg-theme-link-color);
}

.add-btn {
    width: 100%;
    padding: 5px 10px;
    background-color: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    border-radius: 5px;
    outline: none;
    cursor: pointer;
}

.btn {
    border-radius:30px;
    padding:10px 20px;
    font-size:18px;
    font-weight:bold;
    background: rgb(63,147,255);
    border:none;
    color:white;
    text-decoration: none;
    box-shadow: 0 -1px rgb(37, 88, 154) inset;
    transition: 0.2s;
    margin-top:20px;
}

.btn:hover { background: rgb(37, 88, 154); }
.btn:active {
    background: rgb(63,147,255);
    box-shadow: 0 3px rgb(63,147,255) inset;
}

.upClue {
    position: absolute;
    color: white;
    top: 0;
    border-radius: 0px 0px 5px 5px;
    z-index: 9;
    width: 100%;
    height: 20px;
    background-color: #65a2ec;
    text-align: center;
}

.upClueFree {
    position: absolute;
    color: white;
    top: 0;
    border-radius: 0px 0px 5px 5px;
    z-index: 9;
    width: 100%;
    height: 20px;
    background-color: #3fe61a;
    text-align: center;
}

.webBtnCreo {
    border: none;
    border-radius: 0px 0px 5px 5px;
    cursor: pointer;
    z-index: 9;
    width: 100%;
    height: 30px;
    color: white;
    background-color: #65a2ec;
    text-align: center;
    transition: .2s linear;
}

.webBtnCreo:hover {
    box-shadow: 0 0 0 2px white, 0 0 0 4px #3C82F8;
}

.popupContent {
    display: flex;
    font-size: 1.2rem;
}

.discriptionCreoPopup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px 0;
}

@media (max-width: 870px) {
    .popupContent {
        font-size: 1rem;
        flex-direction: column;
        align-items: center;
    }

    .discriptionCreoPopup {
        align-self: flex-start;
    }
}

@media (max-width: 610px) {
    .wrapper {
        margin: 5px 10px;
    }
}

@media (max-width: 592px) {
    .wrapper {
        margin: 5px 6px;
    }
}

@media (max-width: 575px) {
    .wrapper {
        margin: 5px 0px;
    }
}

@media (max-width: 547px) {
    .wrap {
        grid-gap: 0px 0px;
    }

    .title_main,
    .appName,
    .discription_main {
        font-size: 1.2em;
    }

    .discription_main {
        margin: 1px 0;
    }

    .discription, .title {
        font-size: 0.9em;
        margin: 1px 0;
    }

    .add-btn {
        font-size: 1em;
    }
}

@media (max-width: 470px) {
    .title_main,
    .appName,
    .discription_main {
        font-size: 1em;
    }
}
