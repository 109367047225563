.form {
    color: var(--tg-theme-button-text-color);
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    padding: 20px;
}

.title_main {
    margin-bottom: 10px;
}

.input {
    /* width: 100%; */
    padding: 5px;
    margin: 10px 0;
    color: black;
}

.prevData {
    width: 100%;
    padding: 2px;
    margin: 2px 0;
}

.select {
    padding: 10px;
    margin-top: 15px;
}

.button {
    width: 80%;
    background-color: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    border-radius: 5px;
    border: 1px var(--tg-theme-button-color) solid;
    outline: none;
    cursor: pointer;
    margin: auto;
    transition: .5s;
}

.button:hover {
    background-color: white;
    color: black;
    border: 1px var(--tg-theme-button-color) solid;
    cursor: pointer;
    margin: auto;
}

.button:active {
    transform: translateY(-2px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.buttonAdmin {
    width: 80%;
    background-color: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    border-radius: 5px;
    border: 1px var(--tg-theme-button-color) solid;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
    transition: .5s;
}

.webBtnAdd {
    align-self: center;
    margin: 10px 0px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 9;
    width: 80%;
    height: 40px;
    color: white;
    background-color: #65a2ec;
    text-align: center;
    transition: .2s linear;
}

.webBtnAdd:hover {
    box-shadow: 0 0 0 2px white, 0 0 0 4px #3C82F8;
}