.filters_apps {
    height: 70px;
    align-items: center;
    padding: 0 20px;
}

.options {
    color: black;
}

.text {
    margin: 0 15px;
}

.text_info {
    margin: 0 15px;
    text-align: center;
}

.list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 15px;
    margin: 0 10px;
}

.item {
    position: relative;
    margin: 10px;
    font-family: "Trebuchet MS", "Lucida Sans";
    border-radius: 5px;
    border-top: 10px solid #65a2ec; 
    box-shadow: 2px -2px 5px 0 rgba(0,0,0,.1),
         -2px -2px 5px 0 rgba(0,0,0,.1),
        2px 2px 5px 0 rgba(0,0,0,.1),
        -2px 2px 5px 0 rgba(0,0,0,.1);
    font-size: 16px;
    letter-spacing: 2px;
    transition: 0.3s all linear;
    width: 400px;
}

.itemBan {
    position: relative;
    margin: 10px;
    font-family: "Trebuchet MS", "Lucida Sans";
    border-radius: 5px;
    border-top: 10px solid #ff0000; 
    box-shadow: 2px -2px 5px 0 rgba(0,0,0,.1),
         -2px -2px 5px 0 rgba(0,0,0,.1),
        2px 2px 5px 0 rgba(0,0,0,.1),
        -2px 2px 5px 0 rgba(0,0,0,.1);
    font-size: 16px;
    letter-spacing: 2px;
    transition: 0.3s all linear;
    width: 400px;
}

.itemBroke {
    position: relative;
    margin: 10px;
    font-family: "Trebuchet MS", "Lucida Sans";
    border-radius: 5px;
    border-top: 10px solid #ffbf00; 
    box-shadow: 2px -2px 5px 0 rgba(0,0,0,.1),
         -2px -2px 5px 0 rgba(0,0,0,.1),
        2px 2px 5px 0 rgba(0,0,0,.1),
        -2px 2px 5px 0 rgba(0,0,0,.1);
    font-size: 16px;
    letter-spacing: 2px;
    transition: 0.3s all linear;
    width: 400px;
}

@media (max-width: 730px) {
    .item, .itemBan, .itemBroke {
        font-size: 13px;
        letter-spacing: 1px;
    }
}

@media (max-width: 660px) {
    .item, .itemBan, .itemBroke {
        letter-spacing: 0px;
    }
}

@media (max-width: 440px) {
    .item, .itemBan, .itemBroke {
        margin-right: 5px;
        /* padding: 0px 5px; */
    }
}

@media (max-width: 413px) {
    .item, .itemBan, .itemBroke {
        margin-right: 2px;
    }
}
