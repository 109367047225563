.popupContent {
    font-size: 1.2rem;
}

.textMyApps {
    margin: 15px 0px;
}

.wrapperMyApps {
    display: flex;
    flex-direction: column;
    max-width: 400px;
}

.wrapperTestMyApps {
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.wrapperr {
    max-width: 600px;
}
  
.titleTest {
    word-wrap: break-word; /* Перенос длинных слов */
    white-space: normal; /* Перенос строк при необходимости */
    margin: 0 5px;
}

.discriptionMyAppsPopup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px 0;
}

.discription_mainMyApps {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    word-break: break-all;
    margin: 5px 0px;
}

.smallOptionsTest {
    width: 100%;
}

.webBtnMyAppsEdit {
    border: none;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    z-index: 9;
    height: 30px;
    color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, .1);;
    text-align: center;
    transition: .2s linear;
}

.webBtnTestMyApps {
    /* align-self: flex-end; */
    width: 100%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 9;
    margin-top: 15px;
    height: 40px;
    color: white;
    background-color: #65a2ec;
    text-align: center;
    transition: .2s linear;
}

.webBtnTestMyApps:hover {
    box-shadow: 0 0 0 2px white, 0 0 0 4px #3C82F8;
}

@media (max-width: 650px) {
    .wrapperr {
        max-width: 400px;
    }
}